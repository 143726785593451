import React, {memo, useContext} from 'react'
import {Drawer, Row} from 'antd';
import {CloseIcon} from "../../helpers/icon.hepers";
import {DriverContext, IDriverProvider} from "../../context/DriverProvider";


const DriverInfo = () => {
    const {isDark, openDriverDetails, setOpenDriverDetails} = useContext<IDriverProvider>(DriverContext);
    return (
            <Drawer closeIcon={<CloseIcon/>} bodyStyle={{background: isDark ? "black" : "white"}}
                    className="dark:!bg-black" onClose={() => setOpenDriverDetails(false)}
                    visible={openDriverDetails}
                    width="100%">
                <Row className='h-full' justify="center" align="middle" gutter={[16, 32]}></Row>
            </Drawer>
    );
}

export default memo(DriverInfo);
