import httpService from '../../config/axios/axios';
import {AUTH_SUCCESS, LOGOUT_SUCCESS, SESSION_EXPIRED, USER_LOGGED,} from '../reducers/auth/types';
import {getBrowserStorageItem, setBrowserStorageItem} from '../reducers/auth/utils';
import {setError} from './errorActions';
import {KOHANA_DRIVER_PROFILE, KOHANA_DRIVER_SHIFT_STATUS} from "./endpoints";
import {openNotificationWithIcon} from "../../helpers/message.helpers";
import { DriverShiftStatusEnum } from '../../enums/driver.enums';
import loginModel from "../../../shared/fetch/login.model";
import axios from 'axios';
import Qs from 'qs';
import { getGraphqlErrorMessage } from '../../../shared/helpers/graphql.helpers';


interface CompanyDetailsOutput {
    companyLogoUrl: string;
    __typename: string;
  }
  
  interface CompanyOutput {
    CompanyGetFromIdentificator: {
      companydetails: CompanyDetailsOutput;
      __typename: string;
    };
  }
  
  interface ApiResponse {
    data?: CompanyOutput;
  }


let TOKEN_EXPIRATION_TIMEOUT;
let SESSION_EXPIRATION_TIMEOUT;

const userLogged = user => ({
    type: USER_LOGGED,
    user,
});

const authSuccess = (token,driverData) => ({
    type: AUTH_SUCCESS,
    token,
    driverData,
});

const sessionExpired = {
    type: SESSION_EXPIRED,
};

export const userLogoutSuccess = () => {
    clearTimeout(TOKEN_EXPIRATION_TIMEOUT);
    clearTimeout(SESSION_EXPIRATION_TIMEOUT);
    return {
        type: LOGOUT_SUCCESS,
    };
};


export const sessionExpirationTime = () => (dispatch, getState) => {
    clearTimeout(SESSION_EXPIRATION_TIMEOUT);
    SESSION_EXPIRATION_TIMEOUT = setTimeout(() => {
        const accessToken = getBrowserStorageItem('accessToken');
        if (accessToken && !getState().authService.sessionExpired) {
            dispatch(sessionExpired);
        }
    }, Number(100000));
};

export const loginAction = ({phone, password, countryCode, company_identification}, callback?,setLoadingCallback?) => async (dispatch): Promise<void> => {
    const body = {
        phone,
        password: password,
        country_code: countryCode,
        device_id: "26fadd74f598b45e",
        device_type: "1",
        force_login: true,
    }
    try {
       const {data:{AuthDriverLogin}}= await loginModel.login({
            country_code: countryCode,
            phone: phone,
            password: password,
            source: "DRIVER_WEB",
            company_identification : company_identification
        });
        const token = AuthDriverLogin.token ;
        setBrowserStorageItem('driver-token', token);
        
        const {data:{DriverDetails}} = await loginModel
                .getDriverDetails({
                            _id:AuthDriverLogin._id, 
                            device_id: "26fadd74f598b45e1",
                            force_login: AuthDriverLogin.login_status === 'N' ? 0 : 1
                        });

        AuthDriverLogin.company_id=DriverDetails.company_id;
        AuthDriverLogin.is_subscribed_company=DriverDetails.is_subscribed_company;
        AuthDriverLogin.shift_id=DriverDetails.shiftupdate_id;
        AuthDriverLogin.driver_taxi_model=DriverDetails.model_name;
        AuthDriverLogin.driver_taxi_number=DriverDetails.taxi_no;

        dispatch(authSuccess(token,AuthDriverLogin));
         
        callback();

        if(AuthDriverLogin?.login_status === 'N'){
            openNotificationWithIcon('success', 'Rezglo Driver', `Welcome ${AuthDriverLogin?.name}`);
        } else {
            openNotificationWithIcon('success', `Welcome ${AuthDriverLogin?.name}`, 'You had an open session on another device, that session was closed to open this one.' );
        }

    } catch (error){
        openNotificationWithIcon('error', 'Login error', getGraphqlErrorMessage(error));
        localStorage.removeItem('driver-token');
        setLoadingCallback(false);
    } 
};

export const userLogoutAction = ({userId: userId, shiftUpdateId: shiftUpdateId}, callback?: ()=>void) => async(dispatch) => {

    const token = localStorage.getItem('driver-token');
    const API_URL = process.env.REZGLO_API_REST;
    const instance = axios.create({
        baseURL: `${API_URL}`,
        withCredentials: false,
        headers: {
            "Authorization": `Bearer ${token}`,
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    
    const body = {driver_id: userId, shiftupdate_id: shiftUpdateId, source: 'DRIVER_WEB'}

    try {

        
        const {data} = await loginModel.logout(body);

        if(data.AuthDriverLogout){

            if( data.AuthDriverLogout.message !== 'You have logged out successfully.'){
    
                return openNotificationWithIcon('error', 'Server Error', data.AuthDriverLogout.message);
            };
            
            callback && callback();
            instance.post(KOHANA_DRIVER_SHIFT_STATUS, {driver_id: userId, shiftstatus: DriverShiftStatusEnum.OUT});
            return dispatch(userLogoutSuccess());
        
        } else if(data.message && data.message === 'Not authorized' ){

            return dispatch(userLogoutSuccess());

        }

        
    } catch (error) {
        dispatch(setError('logout', error));
        return openNotificationWithIcon('error', 'Server Error', 'Internal Server Error')
    } 

};

export const getUserLogged = (userId) => (dispatch): Promise<void> => {
    const body = {userid: userId}
    return httpService.post(KOHANA_DRIVER_PROFILE, body)
        .then(({data}) => {
            switch (data.status) {
                case 1:
                    return dispatch(userLogged(data?.detail));
                default:
                    return openNotificationWithIcon('error', 'Server Error', data.message);
            }
        })
        .catch(e => {
            dispatch(setError('login', e));
            return openNotificationWithIcon('error', 'Server Error', "Internal Server Error");
        });
};

export const getcompanyLogo = async (companyName) => {
    try {
        const {data:{CompanyGetFromIdentificator:{companydetails:{companyLogoUrl}}}} = await loginModel.getLogoFromCompany(`"${companyName}"`);
        return companyLogoUrl;
    } catch {
        openNotificationWithIcon('error', 'Company logo', "Internal Server Error.")
    } 
}

export const getMeData = async()=>{
    try {
        const {data} = await loginModel.getMe();
        return data;
    } catch (error) {
        console.log(error);
    } 
}

