import io from "socket.io-client";
import {DriverStatus, IDriverData} from "../helpers/interface.helpers";
import {StatusTripEnum} from "../enums/driver.enums";

const REZGLO_API_SOCKET = process.env.REZGLO_API_SOCKET;

class EchoProvider {
    public io: any;
    public uniqueBrowser: string;
    public unique: string;
    public randomUniqueNumber: string;
    public socketUrl: string;
    public isConnectedDriver: boolean;
    public driverStatus: DriverStatus;

    constructor() {
        this.uniqueBrowser = Math.random().toString();
        this.unique = Math.random().toString();
        this.randomUniqueNumber = '';
        this.isConnectedDriver = false;
        this.driverStatus = "F";
        this.socketUrl = `${REZGLO_API_SOCKET}?origin=rezglo&unique=${this.unique}&deviceFrom=${this.uniqueBrowser}`;
        this.connect();
        this.disconnect();
        this.observerDriverLocation();
        this.observerDriverDisable();
    }

    public connect = (callback?: () => void) => {
        this.io = io.connect(
            this.socketUrl,
            {
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 20000,
                reconnectionAttempts: Infinity,
            },
        );
        this.io.on('connect', () => {
            console.log("socket connected")
            this.isConnectedDriver = true;
            const obj = {
                unique: this.unique,
                deviceFrom: this.uniqueBrowser,
                platform: 'WEB',
            };
            this.io.emit('SocketAuthentication', obj, data => {
                this.randomUniqueNumber = data.randomUniqueNumber;
                callback && callback();
                console.log("socket authenticated")
            });
        });
    }

    public sendData = (data?: IDriverData, callback?: (response?: any) => void) => {
        const {driverData: {bookingId, company_id, is_subscribed_company, userid, model_name, name, taxi_id,shift_id,companyAdminId}, location, driverStatus} = data;
        this.driverStatus = driverStatus;
        this.io.emit('driver_location_history_update', {
            "data": {
                "driver_id": String(userid),
                "trip_id": bookingId,
                "locations": `${location?.lat},${location?.lng}|`,
                "status": driverStatus || "F",
                "travel_status": "",
                "device_token": "49356b2176588b77",
                "device_type": "1",
                "above_min_km": "0",
                "bearings": 11.131209373474121,
                "distance": "0.0",
                "shift_id": shift_id,
                "driver_name": name,
                "driver_taxi_number": taxi_id,
                "driver_taxi_model": model_name,
                "waiting_hour": "0.0",
                "company_id": company_id,
                "is_subscribed_company": is_subscribed_company,
                "companyAdminId":companyAdminId
            },
            "unique": this.unique,
            "platform": "ANDROID",
            "app": "DRIVER",
            "id": String(userid)
        }, (response) => {
            callback && callback(response);
        });
    }

    public disconnect = (callback?: (response) => void) => {
        this.io.on('disconnect', (response) => {
            this.isConnectedDriver = false;
            console.log("socket disconnected")
            callback && callback(response);
        });
    }

    public observerDriverLocation = (callback?: (response?: any) => void) => {
        this.io.on('DRIVERS_CURRENT_LOCATION', (response) => {
            callback && callback(response);
        });
    }

    public observerDriverDisable = (callback?: (response?: any) => void) => {
        this.io.on('DRIVER_DISABLE_TRIGGER', (response) => {
            this.isConnectedDriver = false;
            callback && callback(response);
        });
    }

    public updateDriverStatus(driverId, callback?: (response: any) => void) {

        this.io.emit('DRIVER_DISABLE', {
            "driver_id": String(driverId),
            "process": "SHIFT_OUT",
            "unique": this.unique,
        }, (response) => {
            this.isConnectedDriver = false;
            callback && callback(response);
        });
    }

    public getDriverStatus(tripStatus: string, booking: any){
        const storedStatus = localStorage.getItem('driver-status');
        if(storedStatus === 'B') return 'B';
        switch (tripStatus) {
            case StatusTripEnum.SHARED_POSITION:
                return "F"
            case StatusTripEnum.ARRIVED:
                if (booking && !booking.is_ride_now) {
                    return "F"
                } else {
                    return "B"
                }
            case StatusTripEnum.START_TRIP:
                return "B"
            case StatusTripEnum.END_TRIP:
                return "B"
            case StatusTripEnum.FINISH:
                return "F"
            case StatusTripEnum.CANCELED:
                return "F"
        }
    }
}

export default EchoProvider;
