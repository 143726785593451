import SPContainer from "../../app/SharedPosition/container/SPContainer"
const routeAux = process.env.ROUTE_COMPANY;
export const routes = [
    {
        id: 1,
        name: 'index',
        iconClass: 'index',
        routeTo: `${routeAux}app`,
        component: SPContainer,
        permissionSection: true,
    },
];
