export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REFRESHING_TOKENS = 'REFRESHING_TOKENS';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const SESSION_DESTROY = 'SESSION_DESTROY';

export const USER_LOGGED = 'USER_LOGGED';
