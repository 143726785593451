import React, {memo, useEffect} from 'react'
import {BrowserRouter as Router, BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {routes} from "../shared/routes/routes";
import SessionContainer from './Session/container/SessionContainer';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {QueryParamProvider} from "use-query-params";
import Layout from "../shared/layout";
import {getBrowserStorageData} from "../shared/store/reducers/auth/utils";
import {getUserLogged} from "../shared/store/actions/authActions";
// import Home from '../app/Home/components/Home';
// import NotFound from 'shared/components/NotFound';
// import NotFound from 'shared/components/NotFound';

const authSelector = state => ({isAuth: state.authService.isAuth});

const App = () => {
    const routeAux = process.env.ROUTE_COMPANY;
    const dispatch = useDispatch();
    const { isAuth } = useSelector(authSelector, shallowEqual);
    const tripId = getParametroTrip();
    const withTripId= tripId ? `/?trip=${tripId}` : '';
    const companyName = getCompanyNameFromPathname(location.pathname);
    console.log("companynAME",companyName)
    // const { companyName } = useParams<{ companyName: string }>();
    console.log("rutaaaasss",`${companyName}/login${withTripId}`)
  
    useEffect(() => {
        const userId = getBrowserStorageData('session-data')?.userid
        if(userId) dispatch(getUserLogged(userId));
    }, [dispatch]);

    useEffect(()=>{
        const pathnameArray = location.pathname.split('/');
        if(isAuth && pathnameArray.includes('login')) {
            if(companyName === 'login') {
                location.pathname = '/app';
            } else {
                location.pathname = `/${companyName}/app`;
            }
        }
    }, [companyName, isAuth])
    

    return (
        <BrowserRouter>
            <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                    {isAuth &&
                    <Layout>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route key={index} exact path={route.routeTo} component={route.component}/>
                            ))}
                             {/* <Route exact path={`${routeAux}home`} component={Home}/> */}
                            {/* <Route path="*" component={NotFound} /> */}
                        </Switch>
                    </Layout>}
                    {!isAuth && (<Switch>
                        <Route exact path={`${routeAux}login`} component={SessionContainer}/>
                        {
                        (companyName!=='app' && companyName!=='')  ? 
                        <Redirect to={`/${companyName}/login${withTripId}`} />
                        :   <Redirect to={`/login${withTripId}`} />
                        }
                      
                    </Switch>)}
                </QueryParamProvider>
            </Router>
        </BrowserRouter>
    );
};
function getParametroTrip() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('trip');
  }
  function getCompanyNameFromPathname(pathname) {
    const parts = pathname.split('/');
    return parts[1]; // Consideramos que el nombre de la compañía está en la segunda posición del pathname
  }

export default memo(App);
