import React, {memo, useContext} from 'react'
import {Button, Icon} from 'semantic-ui-react';
import {Col, Drawer, Row} from 'antd';
import "../../styles/SharedPosition.css";
import {IDriverProvider, DriverContext} from "../../../../shared/context/DriverProvider";

const EndTripInvoice = () => {
    const {isDark, openInvoiceDrawer, setOpenInvoiceDrawer, booking, isPerHour, history} = useContext<IDriverProvider>(DriverContext);
    const currentPath = history.location.pathname
    return (
        <>
            <Drawer closeIcon={null}
                    bodyStyle={{background: isDark ? "black" : "white"}}
                    className="dark:!bg-black"
                    onClose={()=>setOpenInvoiceDrawer(false)}
                    visible={openInvoiceDrawer && (booking?.fleetFareType === "FIXED_FARE" || isPerHour)}
                    width="100%">
                <Row className='h-full' justify="center" align="middle" gutter={[10, 4]}>
                    <span className={"text-4xl dark:!bg-black dark:!text-white"}>Trip Completed</span>
                    <Col span={24}
                         className="text-7xl font-bold text-center dark:text-white">${booking?.approx_fare || "--"}</Col>
                    <Col span={24} className="text-5xl text-center dark:text-white">
                        <Icon inverted={isDark} size="big" name="map marker alternate"/>
                    </Col>
                    <Row gutter={[10, 20]}>
                    <Col span={24} className="text-2xl text-center dark:text-white">
                        Trip ID: {booking?.number || "--"}
                    </Col>
                    <Col span={24} className="text-2xl text-center dark:text-white">
                        Tip Amount: ${booking?.fare?.tipFare || "--"}
                    </Col>
                    </Row>
                    <Button
                        onClick={()=> {                            
                            history.push(currentPath);
                            history.go(0);
                        }}
                        className={`dark:btn-box-not-shadow dark:!text-black !bg-black !rounded-lg w-[80%] !p-[10px] !text-xl !text-white dark:!bg-white`}
                    >
                        <i className="arrow alternate circle left outline icon"/>
                        Back to Home
                    </Button>
                </Row>
            </Drawer>
        </>
    );
}

export default memo(EndTripInvoice);
