import {getBrowserStorageData, getBrowserStorageItem, setBrowserStorageItem} from './utils';
import {AUTH_SUCCESS, LOGOUT_SUCCESS, SESSION_EXPIRED, REFRESHING_TOKENS, USER_LOGGED} from './types';

const initialState = {
  isAuth: Boolean(getBrowserStorageItem('driver-token')),
  sessionExpired: false,
  isRefreshing: false,
  driverData: getBrowserStorageData('session-data')
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case AUTH_SUCCESS: {
      setBrowserStorageItem('driver-token', action?.token);
      setBrowserStorageItem('session-data', JSON.stringify(action?.driverData));
      setBrowserStorageItem('expiration-at', 36000);
      return { ...state, isAuth: true, isRefreshing: false, driverData: action?.driverData };
    }
    case USER_LOGGED: {
      return { ...state, user: action?.user };
    }
    case REFRESHING_TOKENS:
      return { ...state, isRefreshing: true };
    case SESSION_EXPIRED:
      return { ...state, sessionExpired: true };
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return { isAuth: false, sessionExpired: false, isRefreshing: null };
    default:
      return state;
  }
}
