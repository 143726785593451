import moment from 'moment';
import {TravelTimeType} from "../enums/driver.enums";

export const timeDiff = (firstDate: moment.Moment, secondDate: moment.Moment = moment()) => {
    const cantSec = secondDate.diff(firstDate, 'seconds');
    const cantMin = secondDate.diff(firstDate, 'minutes');
    const cantHour = secondDate.diff(firstDate, 'hour');
    const cantday = secondDate.diff(firstDate, 'days');
    if (cantSec <= 60) {
        return `${cantSec} sec`
    } else if (cantMin <= 60) {
        return `${cantMin} min ${cantSec - (60 * cantMin)} sec`
    } else if (cantHour <= 60) {
        return `${cantHour} h ${cantMin - (60 * cantHour)} min ${cantSec - (60 * cantMin)} sec`
    } else {
        return `${cantday} day/s ${cantHour - (24 * cantday)} h ${cantMin - (60 * cantHour)} min`
    }
};

export const timeDiffTrip = (firstDate: moment.Moment, secondDate: moment.Moment = moment()) =>{
    const cantMin = firstDate.diff(secondDate, 'minutes');
    const cantHour = firstDate.diff(secondDate, 'hour');
    const cantday = firstDate.diff(secondDate, 'days');
    return `${cantday} day/s ${cantHour - (24 * cantday)} h ${cantMin - (60 * cantHour)} min`
}

export const defineTravelTimeType = (travelTime: string | Date | moment.Moment): TravelTimeType => {
    if (moment().diff(moment(travelTime), "hours") > 0) {
        return TravelTimeType.OUT_TIME;
    }
    if (moment(travelTime).diff(moment(), "hours", true) >= 1.5) {
        return TravelTimeType.BOOK_LATER;
    } else if (moment(travelTime).diff(moment(), "hours") >= -0.15 && moment(travelTime).diff(moment(), "hours", true) <= 1.5) {
        return TravelTimeType.RIGHT_NOW;
    }
}
