import {BaseModel} from './base.model';


type LoginType = { country_code: string, phone: string, password: string, source: "DRIVER_WEB", company_identification : string };
type LogoutType = { driver_id: number, shiftupdate_id: number, source: string };
type DriverDetails = { _id: number, device_id?: string, force_login: number }


class LoginModel extends BaseModel {

    login(input: LoginType) {
      const schema = `
          mutation($input: AuthDriverLoginInput!){
            AuthDriverLogin(input: $input, franchiseIds: []){
              _id
              name   
              profile_picture   
              status
              lastname
              email
              phone   
              user_type
              driver_first_login
              login_status
              driver_license_id
              paymentStrypeId    
              token  
              companyAdminId  
			  telephone_code
              phone_iso_country_code
            }
          }
      `;
      return this.driverMutation(schema, {input});
  };

  logout(input: LogoutType){

    const schema = `
        mutation($input: AuthDriverLogoutInput!){
            AuthDriverLogout(input: $input){
                _id,
                message
            }
        }
    `
    return this.driverMutationPrivate(schema, {input})

  }

  getDriverDetails(driver: DriverDetails) {
    const schema = `
      query {
       DriverDetails(_id: ${driver._id}, device_id: "26fadd74f598b45e1", force_login: ${driver.force_login}) {
        driver_status
        travel_status
        trip_id
        user_type
        taxi_id
        taxi_no
        company_id
        driver_wallet
        company_ownerid
        company_address
        is_subscribed_company
        model_name
        shift_status
        shiftupdate_id
        bankaccount_no
        bankname
        mapping_startdate
        mapping_enddate
        commission_subscription
        driver_statistics{
            total_trip
            completed_trip
            total_earnings
            overall_rejected_trips
            cancelled_trips
            today_earnings
            shift_status
            time_driven
            status
        }
        recent_trip_list{
            drop_time
            fare
            model_name
        }
    }
        }
`;
    return this.driverQueryPrivate(schema);
    }

    getLogoFromCompany(company_identification) {
        const schema = `
        query{
            CompanyGetFromIdentificator(company_identificator: ${company_identification}){
                companydetails{
                    companyLogoUrl
                }
            }
        }
    `;
        return this.driverQuery(schema);
    };

    getMe(){
        const schema = `
            query {
                GetMe {
                    _id
                }
            }
        `;
        return this.driverQueryPrivate(schema)
    }
}

const loginModel = new LoginModel();
export default loginModel;
