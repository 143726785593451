import axios from 'axios';
import Qs from 'qs';

const API_URL = process.env.REZGLO_API_REST;
const token = localStorage.getItem('driver-token');
const instance = axios.create({
    baseURL: `${API_URL}`,
    withCredentials: false,
    headers: {
        "Authorization": `Bearer ${token}`,
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    },
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const setAccessToken = token => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default instance;
