import React, {createContext, useContext, useMemo, useReducer, useState} from "react";
import apiModel from "../fetch/api.model";
import bookingModel from "../fetch/booking.model";
import {NumberParam, useQueryParam} from "use-query-params";
import {useTheme} from "../hooks/layout.hooks";
import EchoProvider from "../fetch/socket";
import {TravelTimeType, TripTypeEnum} from "../enums/driver.enums";
import {defineTravelTimeType} from "../helpers/date.helpers";
import {shallowEqual, useSelector } from "react-redux";
import {openNotificationWithIcon} from "../helpers/message.helpers";
import { useParams } from "react-router-dom";

export interface IDriverProvider {
    state?: any;
    loadingSession?: boolean;
    tripId?: number | string;
    booking?: any;
    setState?: any;
    isDark?: boolean;
    isRezglo?: boolean;
    openDriverDetails?: boolean;
    openConfirmationModal?: boolean;
    setOpenDriverDetails?: (open: boolean) => any;
    onOkConfirmationModal?: () => any;
    handleConfirmationModal?: (value: boolean) => any;
    openInvoiceDrawer?: boolean;
    setOpenInvoiceDrawer?: (open: boolean) => any;
    currentTheme?: string;
    bt_primary?: string;
    bt_secondary?: string;
    bt_cancel?: string;
    setTheme?: (theme: string) => any;
    travelTimeType?: TravelTimeType;
    driverAppLogin?: ( callback?: (booking?: any) => void) => any;
    refreshBooking?: (callback?: (booking?: any) => void) => any;
    socket?: EchoProvider,
    driverData?: any,
    waitingTime?: any,
    setWaitingTime?: (value: any) => any;
    tripDetails?: any,
    setTipDetails?: (value: any) => any;
    isPerHour: boolean;
    existTrip:boolean;
    history: any;
}

export const DriverContext = createContext<IDriverProvider>(null)

export const useDriverContext = function () {
    return useContext(DriverContext);
}


const Layout = ({children}) => {
    const ctx = useDriverContext()
    return typeof children === "function" ? children(ctx) : children
}

const authSelector = state => ({driverData: state.authService.driverData});

export const DriverProvider = ({children, reducer, firstValues, history}: { reducer: any, firstValues: any, children: any, history: any }) => {
    const [state, setState] = useReducer(reducer, firstValues, undefined);
    const {isDark, currentTheme, setTheme} = useTheme();
    const [loadingSession, setLoadingSession] = useState<boolean>(false);
    const [booking, setBooking] = useState(undefined);
    const [existTrip, setExistTrip] = useState<boolean>(true);
    const [waitingTime, setWaitingTime] = useState(undefined);
    const [tripDetails, setTipDetails] = useState(undefined);
    const [tripId] = useQueryParam('trip', NumberParam);
    const [actualTripId, setActualTripId] = useState(undefined);
    const [isRezglo, setIsRezglo] = useState<boolean>(false);
    const [openDriverDetails, setOpenDriverDetails] = useState<boolean>(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
    const [openInvoiceDrawer, setOpenInvoiceDrawer] = useState<boolean>(false);
    const [travelTimeType, setTravelTimeType] = useState<TravelTimeType>(TravelTimeType.BOOK_LATER);
    const { driverData } = useSelector(authSelector, shallowEqual);

    const bt_primary = isRezglo ? "!bg-[#2AA7D8]" : "!bg-black"
    const bt_secondary = isRezglo ? "!bg-[#E55130]" : "!bg-black"
    const bt_cancel = isRezglo ? "!bg-[#5F5F5F]" : "!bg-black"

    const isPerHour = useMemo(() => booking?.fleetFareType === TripTypeEnum.PER_HOUR, [booking?.fleetFareType]);    

    const driverAppLogin = async (callback: (booking?: any) => void) => {
        try {
            setLoadingSession(true);
            // if (token) {
                // setToken(token);
              
                setIsRezglo(!driverData?.is_subscribed_company);
                
               
                console.log("este es el driver data",driverData);
                if (tripId ) {
                    const {data: bookingResponse} = await bookingModel.getBookingById(tripId);
                    bookingResponse.BookingGet.number = bookingResponse.BookingGet._id;
                    bookingResponse.BookingGet._id = bookingResponse.BookingGet.booking_id;
                    setActualTripId(bookingResponse.BookingGet.booking_id);
                    setTravelTimeType(defineTravelTimeType(bookingResponse?.BookingGet?.scheduled_time));
                    const bookingObject = {...bookingResponse.BookingGet, companyId: driverData?.company_id};
                    // if (bookingObject?.driver?._id === driverData?.userid){
                        setBooking(bookingObject);
                        callback && callback(bookingObject);
                        !bookingObject?._id && setExistTrip(false);
                    // }
                    // else {
                    //     openNotificationWithIcon(
                    //         'error',
                    //         driverData?.name,
                    //         `You are not the driver assigned to this trip with ID: ${tripId}`
                    //     );
                    //     callback()
                    // }
                }
                else callback();
            // }
        } catch (error) {
            throw new Error(error);
        } finally {
            setLoadingSession(false);
        }
    }

    const refreshBooking = async (callback: (booking: any) => void) => {
        
        
        try {
            setLoadingSession(true);
            
            if( !tripId ){
                
                setBooking(undefined);
                                   
            } else{
                    
                const {data: {BookingGet}} = await bookingModel.getBookingById(tripId);
                setTravelTimeType(defineTravelTimeType(BookingGet.scheduled_time));
                setBooking(BookingGet);
                callback && callback(BookingGet)
            }            

        } catch (error) {
            throw new Error(error);
        } finally {
            setLoadingSession(false);
        }
    }

    const setToken = (token: string) => {
        localStorage.setItem("jwt-driver", token);
    }

    const handleConfirmationModal = (value: boolean) => {
        setOpenConfirmationModal(value)
    }

    const onOkConfirmationModal = async () => {
        setOpenConfirmationModal(false);
        setOpenInvoiceDrawer(true);
        openNotificationWithIcon('success', 'Travel Status', "The trip has ended successfully.");
    }


    return (
        <DriverContext.Provider
            value={{
                state,
                loadingSession,
                tripId: actualTripId,
                booking,
                setState,
                isDark,
                currentTheme,
                setTheme,
                driverAppLogin,
                refreshBooking,
                onOkConfirmationModal,
                handleConfirmationModal,
                openInvoiceDrawer,
                setOpenInvoiceDrawer,
                isRezglo,
                bt_primary,
                bt_secondary,
                bt_cancel,
                travelTimeType,
                openDriverDetails,
                openConfirmationModal,
                setOpenDriverDetails,
                socket: new EchoProvider(),
                driverData: {bookingId: actualTripId, ...driverData},
                waitingTime,
                setWaitingTime,
                tripDetails,
                setTipDetails,
                isPerHour,
                existTrip,
                history
            }}>
            <Layout>
                {children}
            </Layout>
        </DriverContext.Provider>
    )
}


export default DriverProvider;
