import React, { useContext, useMemo } from "react";
import {Col, Row} from 'antd';
import { DriverContext, IDriverProvider } from "../../../shared/context/DriverProvider";
import moment from "moment";
import RouteVerticalLine from "./RouteVerticalLine";
import { Button } from "semantic-ui-react";
import { StatusTripEnum } from "../../../shared/enums/driver.enums";

interface IProps {
    booking: any;
    tripStatus?: StatusTripEnum;
    isTripPerMiles?:boolean;
    configTripButtonLabel?:string;
    isFinished?:boolean;
    configTripButtonBtnColor?:string;
    isLocationBeingShared?:boolean;
    onCancelTrip: ()=>void;
    onConfigTripButton: ()=>void;
}

export const TripStop = (props: IProps) => {
    const {isDark, booking, bt_cancel, isPerHour} = useContext<IDriverProvider>(DriverContext);
    const {tripStatus, isTripPerMiles, isFinished, configTripButtonLabel, configTripButtonBtnColor, onCancelTrip, onConfigTripButton, isLocationBeingShared} = props;
    const isEndTrip = useMemo(() => tripStatus === StatusTripEnum.END_TRIP, [tripStatus]);
    const allowCancel = useMemo(() => tripStatus !== StatusTripEnum.FINISH && !isEndTrip, [tripStatus, isEndTrip]);
    
    return (
        <>
            <Row
                className="mt-[-0.75rem] !border-b-2 border-black text-2xl font-bold dark:text-white dark:border-white">
                Trip Stops
            </Row>
            <Row
                className={`dark:!shadow-none dark:!border-0 ${!isDark ? 'border-route-description' : ''} ${!isDark ? 'border' : ''} border-gray-100 dark:!border-black mt-6`}>
                <Col span={24}
                    className="shadow-md bg-white dark:bg-black text-left px-6 py-3 rounded-t-lg mb-3">
                    <Row>
                        <Col span={12} className="font-bold dark:text-white">
                            {booking ? `${booking?.passenger?.name} ${booking?.passenger?.lastname}` : "-"}
                            { isEndTrip &&
                                <div className="!text-[#959DAD] mb-[-6px] mt-[-5px]">Ongoing now</div>}
                        </Col>
                        <Col span={12} className="text-right font-medium dark:text-white">
                            {moment(isTripPerMiles ? moment() : booking?.pickup_time).format("MMM Do, h:mm A")}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="p-6">
                    <Row>
                        <Col
                            span={allowCancel ? 17 : 24}>
                            <RouteVerticalLine pickup={booking?.current_location}
                                dropOff={booking?.drop_location}
                                routesPoints={booking?.routesPoints}
                                isPerHour={isPerHour} />
                        </Col>
                        {allowCancel &&
                            <Col span={7} >
                                <Button
                                    onClick={onCancelTrip}
                                    disabled={!isLocationBeingShared}
                                    className={`dark:btn-box-not-shadow btn-box-shadow w-max !rounded-lg w-full h-16 !p-[17px] !text-xl ${bt_cancel} !text-white  dark:!bg-dark-color`}
                                >
                                    Cancel
                                </Button>
                            </Col>}
                        <Col span={24} className="mt-20">
                            <Button
                                onClick={onConfigTripButton}
                                className={`w-full h-20 !p-[10px] !text-4xl ${isFinished ? '!bg-white dark:!bg-black dark:!text-white !text-black !cursor-default' : `bordered dark:btn-box-not-shadow btn-box-shadow !rounded-lg ${configTripButtonBtnColor} dark:!bg-dark-color !text-white`}`}
                                disabled={!isLocationBeingShared}
                            >
                                {configTripButtonLabel}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>

    );
};
