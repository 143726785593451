import React from 'react';
import {notification} from 'antd';
import {NotificationPlacement} from "antd/lib/notification";
import {Icon} from "semantic-ui-react";

type NotificationType = 'success' | 'error' | 'info' | 'warning' | 'open'

export const openNotificationWithIcon = (type: NotificationType, message: React.ReactNode, description?: React.ReactNode, placement?: NotificationPlacement) => {
    notification[type]({
        message: (<span className='dark:!text-gray-200'> {message}</span>),
        description: (<span className='dark:!text-gray-200'> {description}</span>),
        placement,
        closeIcon: (<Icon name={'close'} className='!text-xl dark:!text-white !text-grey-900 cursor-pointer' size={'small'}/>),
        className: 'dark:!bg-black dark:!border dark:!border-[#4c545f]'
    });
    
};

export const humanizeText = (str) => {
    let i, frags = str.toLowerCase().split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}
