import React, {useEffect} from 'react'
import 'react-tiny-fab/dist/styles.css';
import {Button, Icon} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getMeData, userLogoutAction, userLogoutSuccess} from '../../store/actions/authActions';
import EchoProvider from 'shared/fetch/socket';




const authSelector = state => ({ driverData: state.authService.driverData });

const FloatingButton = ({isRezglo, socket}: {
    isRezglo: boolean,
    socket: EchoProvider
}) => {

    const dispatch = useDispatch();
    const { driverData } = useSelector(authSelector, shallowEqual);
    

    const checkIfTokenIsValid = async ()=>{
        
        try {
           
            const data = await getMeData();
            
            if(!data){
                dispatch(userLogoutSuccess());        
            }
           
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(()=>{

        checkIfTokenIsValid();
        
    }, []);

    const handleLogout = () => {
        
        dispatch(
            userLogoutAction(
                {
                    userId: driverData?._id, 
                    shiftUpdateId: driverData?.shift_id
                }, ()=>{
                    socket.updateDriverStatus(driverData?._id);                    
                }
            )
        );
    };
        



    return (
        <Button 
            className={"h-[36px] w-[36px] text-center !bg-transparent"}
            text="Logout"
            onClick={handleLogout}
            type="primary"
            icon={
                <Icon 
                    name='power off' 
                    className={`pl-[3px] pt-[1px] text-[2.1rem] ${isRezglo ? "text-[#E55130]" : "text-black dark:text-white"}`} 
                    size="small" 
                    style={{  fontSize: "2rem",opacity: "unset" }}
                />        
            } 
            style={{
                boxShadow: "none",
                padding: "0px 0px 10px 0px",
                borderRadius:"50%"
                
            }}
        >
        </Button>
           
         
    )
};

FloatingButton.propTypes = {    
    isRezglo:PropTypes.bool,
};

export default FloatingButton;
