import {BaseModel} from './base.model';

class ApiModel extends BaseModel {
    url = "https://qa.rezglo.com";

    login(): Promise<any> {
        const body = {
            "phone": "7868683439",
            "password": "e10adc3949ba59abbe56e057f20f883e",
            "device_id": "26fadd74f598b45e",
            "device_type": "1",
            "force_login": false
        }
        return fetch(`${this.url}/driverapi113/index/=?type=driver_login&encode=&lang=en`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
            },
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(r => {
                const data = r.json();
                return data;
            })
    };

    pingServer(){
        const schema = `
            query {
                Ping {
                    response
                }
            }
        `
        return this.driverQuery(schema);
    }
}

const apiModel = new ApiModel();
export default apiModel;
