import React from 'react'
import { memo } from 'react';

const Layout = ({children}) => {
    return (
        <div className="mx-96 <sm:mx-0 <md:mx-0 <lg:mx-6 dark:bg-black  bg-white xl:rounded-md">
            {children}
        </div>
    );
};

export default memo(Layout);
