import React, {memo, useContext} from 'react'
import {Modal, Row} from 'antd';
import "../styles/SharedPosition.css";
import {CloseIcon} from "../../../shared/helpers/icon.hepers";
import {IDriverProvider, DriverContext} from "../../../shared/context/DriverProvider";

interface IProps {
    visible: boolean;
    onCancel: () => void;
}

const CancellationInfo = (props: IProps) => {
    const {visible, onCancel} = props;
    const {isRezglo} = useContext<IDriverProvider>(DriverContext);

    return (
        <Modal
            closeIcon={<CloseIcon/>}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            title={null}
        >
            <Row className="py-10 text-2xl font-bold">
                Your trip has been reassigned to another {isRezglo ? "company": "driver"}.
            </Row>
        </Modal>
    );
}

export default memo(CancellationInfo);
