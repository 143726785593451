const setError = (path, error) => dispatch => {
  dispatch({
    type: 'set_error',
    path,
    error,
  });
};

const clearErrors = () => dispatch => {
  dispatch({
    type: 'clear_error',
  });
};

export { setError, clearErrors };
