import React, { memo, useEffect, useState } from 'react'
import { DriverAppIcon } from "../../../shared/helpers/icon.hepers";
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { getcompanyLogo, loginAction } from '../../../shared/store/actions/authActions';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, EyeOutlined } from '@ant-design/icons';
import { LoginSchema } from "../validations/validateLogin";
import Spining from "../../../shared/components/Spining";
import DPhone from "../../../shared/components/Inputs/DPhone";
import { Button } from "semantic-ui-react";
import { useHistory, useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';


const LoginDriver = () => {
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState<string | undefined>(undefined);
    const dispatch = useDispatch();
    const history = useHistory();
    const [tripId] = useQueryParam('trip', NumberParam);

    const { companyName } = useParams<{ companyName: string }>();

    const fetchLogo = async () => {
        if (companyName) {
            try {
                const logo = await getcompanyLogo(companyName);
                setLogo(logo);
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        fetchLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyName]);

    const isRezglo = companyName === 'rezglo' || !companyName;

    const btPrimary = isRezglo ? "!bg-[#DB2828]" : "!bg-black"

    const formik = useFormik({
        initialStatus: {
            phone: ""
        },
        initialValues: {
            phone: '',
            password: '',
            countryCode: ''
        },
        validationSchema: LoginSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: ({ phone, password, countryCode }) => {
            if (!formik?.status?.phone) {
                setLoading(true);
                const company_identification = companyName;
                dispatch(loginAction({ phone, password, countryCode, company_identification }, () => {
                    const withCompany = companyName ? `/${companyName}/app` : '/app'
                    const withTripId = tripId ? `/?trip=${tripId}` : ''
                    history.push(`${withCompany}${withTripId}`);
                }, setLoadingCallback));
            }
        },
    });
    const setLoadingCallback = () => {
        setLoading(false);
    };

    const { values, errors, handleChange, handleBlur, handleSubmit, touched } = formik;

    return (
        <div
            className="w-full !bg-white min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
            <div className="w-full sm:max-w-md p-5 mx-auto">
                <h2 className={`${isRezglo ? 'mb-12' : 'mb-10'} text-center text-5xl font-extrabold`}>
                    {isRezglo ? <DriverAppIcon />
                        : <div className='flex flex-col'>
                            <img src={logo}
                                className="inset-0 w-full h-full object-cover bg-gray-100 rounded-lg" />
                                <span className='self-end text-gray-600 text-3xl'>driver</span>
                        </div>
                    }
                </h2>
                <Form onFinish={handleSubmit} size="large" layout="vertical">
                    <div className="mb-[-0.60rem]">
                        <DPhone formik={formik} phoneName="phone" />
                    </div>
                    <div className="mb-4">
                        <Form.Item
                            label="Password"
                            name="password"
                            initialValue={values.phone}
                            help={errors.password && touched.password ? (errors.password) : undefined}
                        >
                            <Input.Password
                                name="password"
                                className="mt-[-12px]"
                                placeholder="Input password"
                                iconRender={
                                    visible => (visible ?
                                        (isRezglo ? <EyeTwoTone /> : <EyeOutlined />) :
                                        <EyeInvisibleOutlined />
                                    )
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Form.Item>
                    </div>
                    {/* TODO */}
                    {/* <div className="mt-6 flex items-center justify-between">
                        <div className="flex items-center">
                            <input id="remember_me" type="checkbox"
                                   className="border border-gray-300 text-red-600 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"/>
                            <label htmlFor="remember_me"
                                   className="ml-2 block text-sm leading-5 text-gray-900"> Remember me </label>
                        </div>
                        <a href="#" className="text-sm"> Forgot your password? </a> */}
          {/* </div> */}
          <div className="mt-6">
            <Button
              size="large"
              disabled={loading}
              className={`w-full ${btPrimary} !text-white inline-flex  items-center justify-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold capitalize hover:bg-red-700 active:bg-red-700 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition`}
            >
              {!loading ? 'Sign In' : <Spining loading={loading} style={{ color: 'white' }} />}
            </Button>
          </div>
          {/* TODO */}
          {/* <div className="mt-6 text-center">
                <a href="#" className="underline">Sign up for an account</a>
              </div> */}
        </Form>
      </div>
    </div>
  )
}

export default memo(LoginDriver);
