import * as React from 'react'
import { renderToString } from 'react-dom/server'

import { DriverStatus, GeoInfoType, GoogleType, LocationType } from './interface.helpers'
import startMarker from '../../assets/startMarker.png'
import activeMarker from '../../assets/activeMarker.png'
import normalMarker from '../../assets/normalMarker.png'
import busyMarker from '../../assets/busyMarker.png'
import pickupMarker from '../../assets/pickupMarker.png'
import dropoffMarker from '../../assets/dropoffMarker.png'
import { openNotificationWithIcon } from './message.helpers'
import { WayPointIconSvg } from './icon.hepers'
import moment from 'moment'
import { TravelStatusTypeEnum } from '../enums/driver.enums'

declare let google: GoogleType

export interface IOptionDisplayRoute {
  booking?: any;
  currentLocation?: LocationType;
  map?: any;
  stateSetterCallback?: 
    (response: { 
      estimatedDurationPU?: string; 
      estimatedDurationDO?: string; 
      legs?: any; 
      directionsRenderer?: any;
      borderDirectionsRenderer?: any 
    }) => void;
  headingCallback?: (response: {legs: any, map: any})=>{isHeadingDeviationTooBig: boolean, isTooFarFromRoute: boolean};
  tripStatus?: string;
  isMapInitializing?: boolean;  
};

export interface IOptionsSetMapCenter {
  location? : LocationType;
  cachedLocation: LocationType;
  map: any;
  event:  'init_map' | 'success_position' | 'zoom';
  isMapFullScreen?: boolean;
  booking?: any;
}

export const optionsPosition = {
    enableHighAccuracy: true,
    timeout: 8000,
    maximumAge: 0
};

export const filterAddres = (data: any, key: string): string => {
  return data.address_components.find(item => item.types[0] === key)?.long_name as string
}

export const addMarker = (locationObj: LocationType, map: any, title: string, icon = startMarker) => {
  return new google.maps.Marker({
    position: locationObj,
    map: map,
    animation: google.maps.Animation.DROP,
    title: title,
    icon: icon,
  })
}

export const getInfoLocation = (location: any, callback?: (response: GeoInfoType) => void) => {
  const geocoder = new google.maps.Geocoder()
  geocoder.geocode({ location }).then(response => {
    const result = response.results[0]
    const formattedLocation = result.formatted_address
    const formattedState = filterAddres(result, 'administrative_area_level_1')
    const formattedCountry = filterAddres(result, 'country') || 'Undefined'
    callback &&
      callback({
        locationName: formattedLocation,
        stateName: formattedState,
        countryName: formattedCountry,
        location,
      })
  })
}

export async function getLocationName(location: any, callback?: (response: string) => void) {
  const geocoder = new google.maps.Geocoder()
  const result = await geocoder.geocode({ location })
  const response = result.results[0].formatted_address
  callback && callback(response)
}

export async function getDurationTrip(legs: any[], callback: (response: string)=>void) {
  
  let durationInSeconds = 0;

  for(let i=0; i<legs.length; i++){
    durationInSeconds += legs[i].duration.value
  };

  const duration = moment.duration(durationInSeconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();

  const durationTrip = `${hours>0 ? `${hours} hours, ` :''} ${minutes} minutes`
  
  callback && callback(durationTrip)

}

export const tryInfoPopup = (map, marker, geoInfo: GeoInfoType) => {
  if (map && marker) {
    google.maps.event.addListener(marker, 'click', () => {
      const infowindow = new google.maps.InfoWindow()
      const markerEdit = marker
      markerEdit.setTitle(geoInfo.locationName)
      markerEdit.setPosition(geoInfo.location)
      infowindow.setContent(geoInfo.locationName)
      infowindow.open(map, markerEdit)
    })
  }
}

export const setIconMarker = (marker, driverStatus: DriverStatus = 'A') => {
  const driverIcons = {
    A: activeMarker,
    B: busyMarker,
    F: startMarker,
    T: normalMarker,
  }
  marker?.setIcon(driverIcons[driverStatus])
}

export const calculateAndDisplayRoute = (
  directionsRenderer, borderDirectionsRenderer,
  { booking, currentLocation, map, stateSetterCallback, headingCallback, isMapInitializing}: IOptionDisplayRoute
) => {
  const directionsService = new google.maps.DirectionsService()
  const waypointsAux = []

  if(booking?.travel_status !== TravelStatusTypeEnum.START_TO_PICKUP && booking?.travel_status !== TravelStatusTypeEnum.IN_PROGRESS){
    
    waypointsAux.push({
      location: new google.maps.LatLng(booking?.pickup_latitude, booking?.pickup_longitude),
      stopover: true,
    })
  }

  if (booking?.fleetFareType === 'PER_HOUR') {
    booking.routesPoints.forEach(element =>
      waypointsAux.push({
        location: new google.maps.LatLng(element.drop_latitude, element.drop_longitude),
        stopover: true,
      }),
    )
  }
  const { drop_longitude, drop_location, drop_latitude } = booking
  const isDropoffEmpty = drop_longitude === null && drop_location === null && drop_latitude === null
  
  directionsService
    ?.route({
      origin: new google.maps.LatLng(currentLocation?.lat, currentLocation?.lng),
      destination: isDropoffEmpty
        ? new google.maps.LatLng(currentLocation?.lat, currentLocation?.lng)
        : new google.maps.LatLng(booking?.drop_latitude, booking?.drop_longitude),
      waypoints: waypointsAux,
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then(response => {
      
      const data = response.request
      const legs = response.routes[0].legs;

      const {isHeadingDeviationTooBig, isTooFarFromRoute} = isMapInitializing ? {isHeadingDeviationTooBig: false, isTooFarFromRoute: false} : headingCallback({legs, map});

      if(isMapInitializing || !isHeadingDeviationTooBig || isTooFarFromRoute){
        borderDirectionsRenderer.setDirections(response)
        directionsRenderer.setDirections(response);
      
        if (isDropoffEmpty) {
          legs.pop()
        }
        
        isMapInitializing && data.waypoints?.forEach((waypoint, index) => {
          if(index){
            const pointerIcon = generateMarkers(index)
            createRoutePointMarker(waypoint?.location.location, pointerIcon, { title: legs[index].end_address, map })
          } else{
            addMarker(waypoint?.location.location, map, legs[0].end_address, pickupMarker )
          }
        })
        
        let estimatedDurationPUAux
        let estimatedDurationDOAux = 0
        legs.forEach((leg, index) => {
          if (!index) {
            estimatedDurationPUAux = leg.duration?.text
          } else {
            estimatedDurationDOAux += leg.duration?.value
          }
        })
        if (!isDropoffEmpty && isMapInitializing) {
          addMarker(data.destination.location, map, legs[legs.length-1].end_address, dropoffMarker)
        }
      
        stateSetterCallback({
          estimatedDurationPU: legs[0].duration?.text,
          estimatedDurationDO: (isDropoffEmpty || legs.length > 1) ? secondsToString(estimatedDurationDOAux) : '',
          legs,
          directionsRenderer,
          borderDirectionsRenderer
        })
      };
    })
    .catch(error => {
      openNotificationWithIcon('error', 'Shared Position', error.message);
    })
};

const createRoutePointMarker = (position, icon, { title, map }) => {
  return new google.maps.Marker({
    position: position,
    map: map,
    icon: icon,
    animation: google.maps.Animation.DROP,
    title,
  })
}

const generateMarkers = (index: number, prefix: string = 'P') => {
  const image = renderToString(<WayPointIconSvg letter={`${prefix}${index}`} />)
  return `data:image/svg+xml;utf8,${encodeURIComponent(image)}`
}

const secondsToString = seconds => moment.utc(seconds * 1000).format('HH:mm:ss')

export const checkTravelStatus = (booking: any)=>{

  switch (booking?.travel_status) {
    case TravelStatusTypeEnum.TRIP_CONFIRMED:
    case TravelStatusTypeEnum.START_TO_PICKUP:
    case TravelStatusTypeEnum.IN_PROGRESS:
      return {isTripAcceptedOrInProgress: true}
  
    default:
      return {isTripAcceptedOrInProgress: false}
  }
};

export const setMapCenter = ({location, cachedLocation, map, event, isMapFullScreen, booking}: IOptionsSetMapCenter)=>{

    if(map){
      let newCenter, referenceLocation: LocationType, center

      if(event === 'init_map' || event === 'zoom'){
    
        const { isTripAcceptedOrInProgress } = booking ? checkTravelStatus(booking) : {isTripAcceptedOrInProgress: false};
      
        const viewportHeight = screen.availHeight;
        const fullscreenOffsetY = isTripAcceptedOrInProgress ? viewportHeight/1.70 : viewportHeight/3;

        const offsetX = 0;
        const offsetY = isMapFullScreen ? fullscreenOffsetY : 160;

        const scale = Math.pow(2, map.getZoom());
        const mapProjection = map.getProjection();
        if (mapProjection){
          const worldCoordinateCenter = mapProjection.fromLatLngToPoint(cachedLocation);
          const pixelOffset = new google.maps.Point((offsetX/scale) || 0, (offsetY/scale) || 0);

          const worldCoordinateNewCenter = new google.maps.Point(
            worldCoordinateCenter.x - pixelOffset.x, worldCoordinateCenter.y - pixelOffset.y
          );

          center = mapProjection.fromPointToLatLng(worldCoordinateNewCenter);    
        } else {
          center = null;
        }        
        referenceLocation = cachedLocation;

      } else {        
        center = map.getCenter()
        referenceLocation = location
      };
      
      if(center){
        const heading = map.getHeading()
        const distance = google.maps.geometry.spherical.computeDistanceBetween(cachedLocation, center);
        newCenter = google.maps.geometry.spherical.computeOffset(referenceLocation, distance, heading);
        map.setCenter(newCenter);
      } else {
        openNotificationWithIcon('info', 'Map Info', 'The map could not be centered correctly. Please Zoom In or Zoom Out to readjust it');
        map.setCenter(referenceLocation);
      };
  };
};