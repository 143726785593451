import * as React from 'react';
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/react'

import App from './app/App'
import 'virtual:windi.css'
import ReduxProvider from './shared/store/provider';
import {QueryClientProvider} from 'react-query';
import queryClient from './shared/config/react-query/reactQueryConfig';

if (process.env.APP_ENV !== 'local') {
    
    Sentry.init({
        dsn: process.env.SENTRY_REACT_DSN,
        environment: process.env.APP_ENV
    })

}

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReduxProvider>
                <App/>
            </ReduxProvider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
