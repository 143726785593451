import { useQueryClient } from 'react-query';
import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from './reducers/auth/types';
import authService from './reducers/auth/authReducers';
import { SESSION_DESTROY } from './reducers/auth/types';

const appService = combineReducers({
  authService,
});

export default function useReducers() {
  const qc = useQueryClient();

  return (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
      qc.clear();
    }
    if (action.type === SESSION_DESTROY) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return appService(state, action);
  };
}
