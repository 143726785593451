import React, {memo} from "react";
import {LoginProvider} from "../../../shared/context/session/session.provider";
import LoginDriver from "../components/LoginDriver";

const SessionContainer = memo( () => {
    return (
        <LoginProvider>
            <LoginDriver/>
        </LoginProvider>
    );
});

export default SessionContainer;
