import {gql} from '@apollo/client';
import { get, map, isEmpty } from 'lodash';

import { openNotificationWithIcon } from "../../shared/helpers/message.helpers";

export type GqlSchema = { schema: typeof gql | any, variables?: any };
export type SchemaType = {
    resolveType: string;
    functionType: string;
    client: string;
}

export const getSchemaType = (gqlSchema?: GqlSchema, hasInnerToken: boolean = false): SchemaType => {
    const client = hasInnerToken ? 'publicClient' : 'onboardingClient';
    const [{operation}] = gqlSchema.schema.definitions;
    return {
        resolveType: operation === 'query' ? 'query' : 'mutation',
        functionType: operation === 'query' ? 'query' : 'mutate',
        client,
    }
}

export const getGraphqlErrorMessage = (error: any): string => {
    if (error && error.graphQLErrors && error.graphQLErrors[0]) {
        const {message} = error.graphQLErrors[0];
        return message || undefined;
    }
    if(error && get(error, 'errors.length', false)){
        const {message}= error.errors[0];
        return message || undefined;
    }
    return undefined;
}

const getGraphqlErrorMessageTest = (error: any, typeError:string | undefined ): any[] | undefined => {
    const errorMessageResult = [];
    if (error && error.graphQLErrors) {
        const {message, validation = {}} = error.graphQLErrors[0];

        if(!isEmpty(validation)){
            map(Object.keys(validation), key => {
                return errorMessageResult.push({
                    typeMessage: message, 
                    message: Array.isArray(validation) ? get(validation,`${key}`).join() : get(validation,`${key}`),
                })
            });
        }else {
             errorMessageResult.push({
                typeMessage: typeError || "Server Error.", 
                message:"Internal Server Error.",
            })
        }
        return errorMessageResult || undefined;
    }

    if(error && get(error, 'errors.length', false)){
        const {message}= error.errors[0];
        return message || undefined;
    }
    return undefined;
}

export const showGraphqlErrorNotifications = (error, typeError=undefined): any[] =>   
     map(getGraphqlErrorMessageTest(error, typeError), (_, idx) => {    
        return openNotificationWithIcon(
            'error', 
            // eslint-disable-next-line no-unneeded-ternary
            typeError? typeError : getGraphqlErrorMessageTest(error, typeError)[idx]?.typeMessage, 
            getGraphqlErrorMessageTest(error, typeError)[idx]?.message || "Internal Server Error.",
    );
}); 
