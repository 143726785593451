import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import useReducers from './reducers';

const middleware = [thunk];

function ReduxProvider({ children }) {
  const rootReducers = useReducers();
  const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(...middleware)));
  return <Provider store={store}>{children}</Provider>;
}

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReduxProvider;
