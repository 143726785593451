import React, {memo, useContext} from 'react'
import {Header, Icon, Image} from 'semantic-ui-react';
import {Col, Drawer, Row} from 'antd';
import "../styles/SharedPosition.css";
import {CloseIcon} from "../../../shared/helpers/icon.hepers";
import {IDriverProvider, DriverContext} from "../../../shared/context/DriverProvider";
import {StatusTripEnum} from '../../../shared/enums/driver.enums';
import driverImage from "../../../assets/driverImage.png";

interface IProps {
    booking: any;
    tripStatus: StatusTripEnum;
    visible: boolean;
    onCancel: () => void;
    onOpen: () => void;
}

const PassengerInfo = (props: IProps) => {
    const {isDark} = useContext<IDriverProvider>(DriverContext);
    const {booking, tripStatus, visible, onOpen, onCancel} = props;
    return (
        <>
            <Col span={24} className="mt-10 !border-b-2 border-black dark:border-white">
                {(tripStatus === StatusTripEnum.ARRIVED || tripStatus === StatusTripEnum.START_TRIP) &&
                <Header as='h4' textAlign="center">
                    <Row
                        justify="center"
                        className="text-xl !mb-[9px] dark:text-white font-bold cursor-pointer text-right text-blue-700 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                        onClick={onOpen}
                    >
                        <Icon className="!pl-[10px]" inverted={isDark} color={!isDark ? "blue" : null}
                              name="file"/>Greeting Sign
                    </Row>
                </Header>
                }
            </Col>
            <Drawer closeIcon={<CloseIcon/>} bodyStyle={{background: isDark ? "black" : "white"}}
                    className="dark:!bg-black" onClose={onCancel}
                    visible={visible}
                    width="100%">
                <Row className='h-full' justify="center" align="middle" gutter={[16, 32]}>
                    <Col span={24}
                         className="text-7xl font-bold text-center dark:text-white">{`${booking?.passenger?.name} ${booking?.passenger?.lastname || ''}`}</Col>
                    <Col span={4} className="text-bold dark:text-white">
                        {booking?.peopleNumber} <Icon inverted={isDark} name="users" size="big"/>
                    </Col>
                    <Col span={4} className="text-bold dark:text-white">
                        {booking?.luggage} <Icon inverted={isDark} size="big" name="briefcase"/>
                    </Col>
                    <Col span={4} className="text-bold dark:text-white">
                        {booking?.wheelchairLiftRamp} <Icon inverted={isDark} size="big" name="wheelchair"/>
                    </Col>
                    <Col span={4} className="text-bold dark:text-white">
                        {booking?.childrenCategory1 + booking?.childrenCategory2 + booking?.childrenCategory3} <Icon
                        inverted={isDark} size="big" name="child"/>
                    </Col>
                    <Col span={4} className="text-bold dark:text-white">
                        {booking?.animalsNumber} <Icon inverted={isDark} size="big" name="paw"/>
                    </Col>

                    <Col span={24} className="text-center">
                        <Header as='h2' size="huge">
                            <Image circular src={booking?.driver?.profile_picture || driverImage }/>
                            <Header.Content className="dark:!text-white">
                                {booking?.driver?.name}
                                <Header.Subheader className="text-left dark:!text-white">Driver</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
}

export default memo(PassengerInfo);
