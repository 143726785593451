import { Col, Drawer, Row } from 'antd';
import React, { memo, useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Spining from "../../../../shared/components/Spining";
import { DriverContext, IDriverProvider } from "../../../../shared/context/DriverProvider";
import "../../styles/SharedPosition.css";
import { isNullOrUndefined } from '../../../../shared/helpers/string.helpers';

const EndTripPaymentSelection = ({payTrip, loading, PassengerInfo:{ isPaymentCard = false, isPaymentWallet = false, defaultCreditCard = undefined } = {} }) => {
    const {isDark, openInvoiceDrawer, booking, tripDetails} = useContext<IDriverProvider>(DriverContext);
    const multiplier = booking?.distance_unit==="miles"? 0.000621371192 : 0.001;
    const paymentTypeId = (paymentType)=>{

        switch (paymentType) {          
            case "Wallet":
                return 5;
            case "Card":
                return 2;
            case "Paypal":
                return 3;
            case "insurance":
                return 6;
            case "prepaid":
                return 7;
            case "Cash":
            default:
                return 1;
          }
    }

    const handleClick = paymentType => {
        payTrip && payTrip(paymentTypeId(paymentType));
    }

    return (
            <Drawer closeIcon={null}
                    bodyStyle={{background: isDark ? "black" : "white"}}
                    className="dark:!bg-black"
                    visible={openInvoiceDrawer && booking?.fleetFareType === "PER_MILES"}
                    width="100%">
                <Spining
                    loading={loading}
                    size={85}
                >
                <Row justify="center" align="middle" gutter={[10, 20]}>
                    <span className={"text-4xl dark:!bg-black dark:text-white font-bold"}>Fare Calculator</span>
                </Row>
                <Row className={"pt-10"} gutter={[5, 5]} justify="center">
                    <table
                        className={`ui basic collapsing table !border-none ${isDark? "inverted": ""}`}>
                        <thead>
                        </thead>
                        <tbody>
                        <tr className={`text-2xl !border-none`} style={{boxShadow: "none !important"}}>
                            <Row>
                                <Col span={10}>
                                    <td className={"!border-none"}>
                                        <div className="content font-bold">
                                            Pick-up Location:
                                        </div>
                                    </td>
                                </Col>
                                <Col span={14}>
                                    <td className={"!font-normal !border-none"}>
                                        {tripDetails?.pickup? tripDetails.pickup : "--"}
                                    </td>
                                </Col>
                            </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}>
                            <Row>
                                <Col span={10}>
                                    <td className={"!border-none"}>
                                        <div className="content font-bold">
                                            Drop-off Location:
                                        </div>
                                    </td>
                                </Col>
                                <Col span={14}>
                                    <td className={"!font-normal !border-none"}>
                                        { tripDetails?.drop? tripDetails?.drop : "--" }
                                    </td>
                                </Col>
                            </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}><Row>
                            <Col span={10}>
                                <td className={"!border-none"}>
                                    <div className="content font-bold">
                                        Total Distance:
                                    </div>
                                </td>
                            </Col>
                            <Col span={14}>
                                <td className={"!font-normal !border-none"}>
                                    {booking?.approx_distance? `${(booking?.approx_distance * multiplier).toFixed(1)} ${booking?.distance_unit}`: "--"}
                                </td>
                            </Col>
                        </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}><Row>
                            <Col span={10}>
                                <td className={"!border-none"}>
                                    <div className="content font-bold">
                                        Travel Time:
                                    </div>
                                </td>
                            </Col>
                            <Col span={14}>
                                <td className={"!font-normal !border-none"}>
                                    {tripDetails?.minutes_traveled? `${(tripDetails?.minutes_traveled*0.0166667).toFixed(2)}h` : "--"}
                                </td>
                            </Col>
                        </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}><Row>
                            <Col span={10}>
                                <td className={"!border-none"}>
                                    <div className="content font-bold">
                                        Trip Fare:
                                    </div>
                                </td>
                            </Col>
                            <Col span={14}>
                                <td className={"!font-normal !border-none"}>
                                    {booking?.approx_fare? (Number(booking?.approx_fare) > 0 ? `$${booking?.approx_fare}` 
                                    : (tripDetails?.approx_fare? `$${tripDetails?.approx_fare}` : "--")) 
                                    : (tripDetails?.approx_fare? `$${tripDetails?.approx_fare}` : "--")}
                                </td>
                            </Col>
                        </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}><Row>
                            <Col span={10}>
                                <td className={"!border-none"}>
                                    <div className="content font-bold">
                                        Total:
                                    </div>
                                </td>
                            </Col>
                            <Col span={14}>
                                <td className={"!font-normal !border-none"}>
                                    {tripDetails?.total_fare? `$${tripDetails?.total_fare}` : "--"}
                                </td>
                            </Col>
                        </Row>
                        </tr>
                        <tr className={`text-2xl !border-none`}><Row>
                            <Col span={10}>
                                <td className={"!border-none"}>
                                    <div className="content font-bold">
                                        Amount to Pay:
                                    </div>
                                </td>
                            </Col>
                            <Col span={14}>
                                <td className={"!font-normal !border-none"}>
                                    {tripDetails?.total_fare? `$${tripDetails?.total_fare}` : "--"}
                                </td>
                            </Col>
                        </Row>
                        </tr>
                        </tbody>
                    </table>
                </Row>
                <Row justify="center" align="middle" gutter={[10, 20]} className={"pt-10"}>
                    <span className={"text-4xl dark:!bg-black dark:text-white font-bold"}>Payment Methods</span>
                </Row>

                <Row className="pt-10" justify="center" align="middle" gutter={[30, 30]}>
                    <Col span={8} className="text-2xl text-center dark:text-white">
                        <Icon inverted={isDark} size="big" circular name={"money bill alternate outline"}
                              className="ml-3 border-2 btn-box-shadow"
                              onClick={()=>handleClick("Cash")}
                        />
                        <h4 className={"dark:!bg-dark dark:text-white mt-3 font-bold"}>Cash</h4>
                    </Col>

                    {booking?.fleetFareType === "PER_MILES" && (
                        <>  
                            {!isNullOrUndefined(isPaymentCard) && !isNullOrUndefined(defaultCreditCard) && isEqual(isPaymentCard, true) && (
                                <Col span={8} className="text-2xl text-center dark:text-white">
                                    <Icon inverted={isDark} size="big" circular name={"credit card"}
                                        className="ml-3 border-2 btn-box-shadow"
                                        onClick={()=> {
                                            handleClick("Card")
                                        }}
                                    />
                                    <h4 className={"dark:!bg-dark dark:text-white mt-3 font-bold"}>Card</h4>
                                </Col>
                            )}  

                            {!isNullOrUndefined(isPaymentWallet)  && isEqual(isPaymentWallet, true) && (
                                <Col span={8} className="text-2xl text-center dark:text-white">
                                    <Icon inverted={isDark} size="big" circular name={"google wallet"}
                                        className="ml-3 border-2 btn-box-shadow"
                                        onClick={()=>handleClick("Wallet")}
                                    />
                                    <h4 className={"dark:!bg-dark dark:text-white mt-3 font-bold"}>Wallet</h4>
                                </Col>
                            )}
                        </>
                    )}

                </Row>

                </Spining>
            </Drawer>
    );
}

EndTripPaymentSelection.propTypes = {    
    payTrip:PropTypes.func.isRequired,
    loading:PropTypes.bool.isRequired,
    PassengerInfo:PropTypes.object,
};

export default memo(EndTripPaymentSelection);
