import {Label} from "semantic-ui-react";
import {Col, Row} from "antd";
import {TimeIcon} from "../../../shared/helpers/icon.hepers";
import React, {memo} from "react";

const TimerTrip = ({timerLabel, count, className}: { timerLabel?: string, count?: number | any | React.ReactNode, className?: string }) => (
    <Label size="big"
           className={`!bg-white !border !shadow !border-[#7C7C7C] !pt-[4px] !pr-[22px] !pb-[9px] !pl-[5px] ${className}`}>
        <Row>
            <Col span={22}>
                <div className="text-left text-xs text-[#5F5F5F] mb-[3px]">{timerLabel || "Travel Time"}</div>
                <div className="text-left !pl-[5px] text-black">{count || "-"}</div>
            </Col>
            <Col span={2} className="text-right !mt-[10px]">
                <TimeIcon fillColor="black" />
            </Col>
        </Row>
    </Label>
)

export default memo(TimerTrip);
