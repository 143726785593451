import React, { memo, useContext } from 'react'
import { Header, Icon } from 'semantic-ui-react';
import { Col, Row } from 'antd';
import "../styles/SharedPosition.css";
import { DriverContext, IDriverProvider } from "../../../shared/context/DriverProvider";
import moment from "moment";
import { TimeIcon } from '../../../shared/helpers/icon.hepers';

interface IProps {
    booking: any;
}

const TripDetails = (props: IProps) => {
    const { isDark, isPerHour } = useContext<IDriverProvider>(DriverContext);
    const { booking } = props;
    const getInfoLabel = (label) => <label className="text-xl font-bold ml-2 dark:text-white">{label}:</label>;

    return (
        <>
            <Col span={24} className="mt-10">
                <Header as='h4'>
                    <div
                        className="text-xl font-bold dark:text-white dark:border-white !border-b-2 border-black">Trip
                        Details
                    </div>
                </Header>
            </Col>
            <Col span={24} className="mt-4">
                <Row gutter={[0, 16]}>
                    <Col span={12}>
                        <Icon inverted={isDark} size="large" name="checked calendar" />
                        {getInfoLabel("Pick-Up Date")}
                    </Col>
                    <Col span={12} className="!text-xl w-full dark:text-white text-center">
                        {moment(booking?.pickup_time).format("dddd, MMMM D YYYY")}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="clock outline" />
                        {getInfoLabel("Pick-up Time")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {moment(booking?.pickup_time).format("h:mm a")} - {moment(booking?.pickup_time).format("HH:mm")}
                    </Col>

                    {isPerHour && (
                        <>
                            <Col span={13} >
                                <TimeIcon fillColor={isDark ? 'white' : 'black'} />
                                <label className="text-xl font-bold ml-4 dark:text-white">Hourly Booking:</label>
                            </Col>
                            <Col span={11} className="!text-xl w-full text-center dark:text-white">
                                {booking?.hours}
                            </Col>
                        </>
                    )}

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="users" />
                        {getInfoLabel("Passenger")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {booking?.peopleNumber + booking?.childrenCategory1 + booking?.childrenCategory2 + booking?.childrenCategory3 + booking?.animalsNumber || "-"}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="child" />
                        {getInfoLabel("Children")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {booking?.childrenCategory1 + booking?.childrenCategory2 + booking?.childrenCategory3 || "-"}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="paw" />
                        {getInfoLabel("Pets")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {booking?.animalsNumber || "-"}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="wheelchair" />
                        {getInfoLabel("Accessible")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {booking?.wheelchairLiftRamp ? "Yes" : "No"}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="briefcase" />
                        {getInfoLabel("Luggage")}
                    </Col>

                    <Col span={11} className="!text-xl w-full text-center dark:text-white">
                        {booking?.luggage}
                    </Col>

                    <Col span={13}>
                        <Icon inverted={isDark} size="large" name="road" />
                        {getInfoLabel("Trip Type")}
                    </Col>
                    <Col span={11} className="!text-xl w-full text-center dark:text-white capitalize">
                        {booking?.fleetFareType?.replace("_", " ").toLowerCase()}
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default memo(TripDetails);
