import * as React from 'react'
import {ISessionContext} from './session.interface'

export const SessionContext = React.createContext<ISessionContext>({})

export const LoginProvider = ({children}: { children: any }) => {
    return (
        <SessionContext.Provider
            value={{isForgotPassword: false}}
        >
            {children}
        </SessionContext.Provider>
    )
}
