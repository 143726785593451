import React, {memo, useCallback, useEffect, useState} from "react";
import {Col, Divider, Form, Input, Row, Select, Space, Typography} from 'antd';
import {useGetCountries} from "../../hooks/request/country.hooks";
import {allowNumberPhone} from "../../helpers/number.helpers";


const {Option} = Select

interface IProps {
    formik: any,
    phoneName: string,
    label: string,
    countryCodeName: string,
}

const DPhone = (props: IProps) => {
    const {formik, label, phoneName, countryCodeName} = props
    const {countries, loading} = useGetCountries()
    const [countrySelected, setCountrySelected] = useState(undefined);
    const {errors, touched, handleBlur, values, setFieldValue, status} = formik;

    const validatePhoneNumber = useCallback((phone: string, isoCode: any = countrySelected?.iso_country_code) => {
        
        
    },[countrySelected?.iso_country_code])

    const onChangeNumber = useCallback(
        (e) => {
            const { value } = e.target;
            if (allowNumberPhone(value, countrySelected?.iso_country_code)) {
                setFieldValue(phoneName, value);
                validatePhoneNumber(value);
            }
        },
        [setFieldValue, phoneName, validatePhoneNumber, countrySelected?.iso_country_code],
    );

    const onChangeCountryCode = useCallback(
        (value) => {
            const country = countries.find(country => country._id === value);
            values.countryCode = `+${country?.telephone_code}`
            setCountrySelected(country);
            validatePhoneNumber(values[phoneName], country?.iso_country_code);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setFieldValue, validatePhoneNumber, countryCodeName, setCountrySelected, countries, values, phoneName],
    );

    useEffect(() => {
        setFieldValue(countryCodeName || "countryCode", "+1");
    }, [setFieldValue, countryCodeName]);

    return (
        <Form.Item
            label={label}
            name={phoneName}
            help={((errors[phoneName] && touched[phoneName]) || status[phoneName]) ? errors[phoneName] || status[phoneName] : undefined }
        >
            <Input.Group>
                <Row
                    className="mt-[-12px] border border-gray-300 divide-x divide-gray-300" style={{flexFlow: "initial"}}>
                    <Col flex="auto">
                        <Select
                            loading={loading}
                            showSearch
                            optionFilterProp="title"
                            optionLabelProp="label"
                            className="!w-full"
                            defaultValue={1}
                            dropdownStyle={{
                                minWidth: 250
                            }}
                            bordered={false}
                            onChange={onChangeCountryCode}
                            filterOption={(input, option) =>
                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {!countries.length && (
                                <Option
                                    title={`+ 1 United States`}
                                    label={<RenderSelectedCountry iso_country_code="US" telephone_code="1"/>}
                                    value={1}
                                    key={1}
                                >
                                    <RenderCountryOption
                                        country_name="United States"
                                        telephone_code="1"
                                        iso_country_code="US"
                                    />
                                </Option>
                            )}
                            {countries?.map(({_id, country_name, telephone_code, iso_country_code}: ICountryOptionProps, index) => (
                                <Option
                                    title={`+ ${telephone_code} ${country_name}`}
                                    label={
                                        <RenderSelectedCountry
                                            iso_country_code={iso_country_code}
                                            telephone_code={telephone_code}
                                        />
                                    }
                                    value={_id}
                                    key={_id}
                                >
                                    <RenderCountryOption
                                        country_name={country_name}
                                        telephone_code={telephone_code}
                                        iso_country_code={iso_country_code}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col flex="auto">
                        <Input
                            type="tel"
                            name={phoneName}
                            bordered={false}
                            value={values[phoneName]}
                            onChange={onChangeNumber}
                            onBlur={handleBlur}/>
                    </Col>
                </Row>
            </Input.Group>
        </Form.Item>
    )
}

DPhone.defaultProps = {
    formik: undefined,
    phoneName: "phone",
    label: "Phone",
    countryCodeName: "countryCode",
}

interface ICountryOptionProps {
    _id: number;
    telephone_code: string;
    country_name: string;
    iso_country_code: string;
}

export const RenderCountryOption = memo(({iso_country_code, country_name, telephone_code}: Partial<ICountryOptionProps>) => (
    <Row>
        <Col span={4}>
            <img
                className="mr-[6px] w-[25px] h-[25px]"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso_country_code || 'US'}.svg`}
            />
        </Col>
        <Col span={15}>
            <Typography className="truncate overflow-hidden">{country_name}</Typography>
        </Col>
        <Col span={5} className="text-right">
            <Typography><strong>+{telephone_code}</strong></Typography>
        </Col>
    </Row>
))

export const RenderSelectedCountry = memo(({iso_country_code, telephone_code}: Partial<ICountryOptionProps>) => (
    <Space size={0} split={<Divider type="vertical" className="bg-gray-300 !h-[1.9em]"/>}>
        <img className="mr-[6px] w-[25px] h-[25px]"
             src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso_country_code || 'US'}.svg`}/>
        <Typography>{`+ ${telephone_code}`}</Typography>
    </Space>
))

export default memo(DPhone);
