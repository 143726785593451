import { BaseModel } from './base.model';
import { gql } from 'apollo-boost';

export const CountryFragment = gql`
  fragment CountryFragment on CountryOutput {
    _id
    country_name
    telephone_code
    default
    iso_country_code
  }
`;
export const StateFragment = gql`
  fragment StateFragment on StateOutput {
    state_id
    state_name
    default
  }
`;
export const CityFragment = gql`
  fragment CityFragment on CityOutput {
    city_id
    city_name
    zipcode
    default
  }
`;

class CountryModels extends BaseModel {
  list(config) {
    const schema = `
    query ($input:SearchCountryInput){
      GetCountries: PublicGetCountries(input: $input){
       _id
       country_name
       iso_country_code
       default
      }
    }`;
    return this.driverQuery(schema, config);
  }
  get_all(config) {
    const schema = `query{
  CountryList: PublicCountryList{
    _id
    country_name
    currency_code
    iso_country_code
    telephone_code
    stateinfo
  }
}`;
    return this.driverQuery(schema, config);
  }

  phonCodeList(config) {
    const schema = `
    query ($input:SearchCountryInput){
      GetCountries: PublicGetCountries (input: $input){
        _id
        country_name
        telephone_code
      }
    }`;
    return this.driverQuery(schema, config);
  }

  statesList(config) {
    const schema = `
    query ($input:SearchCountryInput){
      GetCountries: PublicGetCountries(input: $input){
        _id
        country_name
        stateinfo {
          state_id
          state_name
          default
        }
      }
    }`;
    return this.driverQuery(schema, config);
  }

  citiesList(config, stateId) {
    const schema = `
    query ($input:SearchCountryInput){
      GetCountries: PublicGetCountries(input: $input){
       _id
       country_name
      stateinfo(state_id:${stateId}) {
       state_id
       state_name
       cityinfo{
         city_id
         city_name
         default
       }
      }
      }
    }`;
    return this.driverQuery(schema, config);
  }

  publicList(config = {}) {
    const schema = `
    query{
      CountryList: PublicCountryList {
        _id
        country_name
        currency_code
        iso_country_code
        telephone_code
      }
    }`;
    return this.driverQuery(schema, config);
  }
}

const countryModel = new CountryModels();
export default countryModel;
