import { Col, Row } from "antd";
import { Label } from "semantic-ui-react";
import React, { memo } from "react";

const RouteVerticalLine = ({ pickup, dropOff, routesPoints, isPerHour }: { pickup?: string, dropOff?: string, routesPoints: any, isPerHour:boolean }) => {
    return (
        <Row>
            <Col span={3}>
                <Label circular className="!bg-[#44F724] !ml-[1.5px]" empty />
                { isPerHour && routesPoints?.map(r => {
                    return <>
                        <div className="leading-none mt-[3px] !mb-[6px] !ml-[2px]">
                            <Label circular className="!text-[7px] !bg-[#78849E4B] !ml-[1px]" empty />
                            <br />
                            <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                            <br />
                            <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                            <br />
                            <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                            <br />
                            <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                            <br />
                            <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                        </div>
                        <Label circular color="red" empty /></>
                })}
                <div className="leading-none mt-[3px] !mb-[6px] !ml-[2px]">
                    <Label circular className="!text-[7px] !bg-[#78849E4B] !ml-[1px]" empty />
                    <br />
                    <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                    <br />
                    <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                    <br />
                    <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                    <br />
                    <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                    <br />
                    <Label circular className="!text-[7px] !bg-[#78849E4B]" empty />
                </div>
                <Label circular color="red" empty />
            </Col>
            <Col span={21}>
                <Row className="-mt-[11px]">
                    <Col span={24} className="text-[#959DAD] dark:text-white text-lg">Pick-Up Location</Col>
                    <Col span={24} className="text-xl font-bold text-[#454F63] dark:text-white">
                        <div>
                            {pickup || "11401 NW 12th St, Miami, FL 33172, USA"}
                        </div>
                    </Col>
                </Row>
                {isPerHour && routesPoints?.map(r => {
                    return <Row className="mt-[40px]">
                        <Col span={24} className="text-[#959DAD] text-lg dark:text-white">Stop Location</Col>
                        <Col span={24} className="text-xl font-bold text-[#454F63] dark:text-white">
                            <div>
                                {r.drop_location || "11401 NW 12th St, Miami, FL 33172, USA"}
                            </div>
                        </Col>
                    </Row>
                })}

                <Row className="mt-[40px]">
                    <Col span={24} className="text-[#959DAD] text-lg dark:text-white">Drop-Off Location</Col>
                    <Col span={24} className="text-xl font-bold text-[#454F63] dark:text-white">
                        <div>
                            {dropOff || ""}
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>)
}

export default memo(RouteVerticalLine);
