import * as React from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

interface IProps {
    size?: number,
    loading?: boolean
    text?: string
    style?: React.CSSProperties,
    defaultAntd?: boolean,
    children?: any,
}

const Spining = (props: IProps) => {
        const {size, style, text, loading, defaultAntd, children, ...otherProps} = props
        const antIcon = !defaultAntd ? <LoadingOutlined style={{fontSize: size || 35, left: '40%'}} spin/> : undefined;
        return (<Spin indicator={antIcon} spinning={loading} tip={text} style={style} {...otherProps}>{children}</Spin>)
}

export default React.memo(Spining)
